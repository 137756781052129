<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card companies -->
            <div class="row">
                <div class="col-xl-3 col-lg-6 mb-3" v-for="company in companies" :key="company.company_id">
                    <stats-card :title="company.company.description" type="gradient-red" sub-title="" icon="ni ni-building"
                        class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <base-button size="lg" type="primary" @click="handleSetting(company)">Seleccionar</base-button>
                        </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

    </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";

export default {
    components: {

    },
    data() {
        return {
            companies: [],
            showSetting: false,

        };
    },
    mounted() {
        console.log('Setting component')
        this.companies = this.$store.getters.GET_USER?.companies ?? [];
        console.log('Companies', this.companies);
    },
    methods: {
       async handleSetting(item) {
            console.log('Selected',item);
            // this.showSetting = type;
            await this.$store.dispatch("profile/selectedCompany", item.company);
        }
    },
    
};
</script>
<style></style>
  